<template>
  <div>
    <RouterView/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
