import { createRouter, createWebHistory } from 'vue-router'
import CodeHandler from '../components/CodeHandler.vue'
import NotFound from '../components/NotFound.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/404'
  },
  {
    path: '/:code',
    name: 'Code',
    props: true,
    component: CodeHandler
  },
  {
    path: '/404',
    name: '404',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;