<script>
import links from '../../public/links.js'
export default {
    name: 'CodeHandler',
    data() {
        return {
            code: this.$route.params.code,
            found: false
        }
    },
    mounted() {
        for (let i = 0; i < links.length; i++) {
            if (links[i].code === this.code) {
                location.href = links[i].url;
                this.found = true;
                break;
            }
        }
        if (!this.found) {
            location.href = '/404';
        }
    }
}
</script>
